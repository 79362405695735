import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { io } from 'socket.io-client';
import QueryString from 'qs';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENV,
});

export const clearTokens = async () => {
  if (typeof window === 'undefined') {
    try {
      const { cookies } = await import('next/headers');
      cookies().delete('access_token');
      cookies().delete('refresh_token');
    } catch {
      // none
    }
  } else {
    const { default: Cookies } = await import('js-cookie');

    Cookies.remove('access_token');
    Cookies.remove('refresh_token');

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
};

const refreshAccessToken = async () => {
  // Використовуємо js-cookie на клієнті і req.headers.cookie на сервері

  let refreshToken;
  if (typeof window === 'undefined') {
    const { cookies } = await import('next/headers');
    refreshToken = cookies().get('refresh_token')?.value;
  } else {
    const { default: Cookies } = await import('js-cookie');
    refreshToken =
      Cookies.get('refresh_token') ||
      window?.localStorage?.getItem('refresh_token');
  }

  if (!refreshToken) {
    return '';
  }

  // const response = await api.post('/api/v1/auth/access_token/refresh', {
  //   refreshToken,
  // });
  const { data } = await axios.post(
    `${process.env.NEXT_PUBLIC_API_ENV}/api/v1/auth/access_token/refresh`,
    refreshToken
  );

  const newAccessToken = data?.data?.accessToken?.token;
  if (newAccessToken) {
    // NB: we use js-cookie for CSR and res.setHeader() for SSR
    if (typeof window === 'undefined') {
      const { cookies } = await import('next/headers');
      cookies().set('access_token', newAccessToken);
    } else {
      const { default: Cookies } = await import('js-cookie');
      Cookies.set('access_token', newAccessToken);
      window?.localStorage?.setItem('refresh_token', newAccessToken);
    }
  }
  return data?.data?.accessToken?.token;
};

api.interceptors.request.use(
  async config => {
    let accessToken;

    let otp;
    let nonce;

    if (typeof window === 'undefined') {
      const { cookies, headers } = await import('next/headers');
      accessToken = cookies().get('access_token')?.value;

      const referer = headers().get('referer') || '';

      const query = QueryString.parse(referer);

      otp = query.otp;
      nonce = query.nonce;
    } else {
      const { default: Cookies } = await import('js-cookie');
      accessToken =
        Cookies.get('access_token') ||
        window?.localStorage?.getItem('access_token');

      const query = QueryString.parse(window.location.search.slice(1));

      otp = query.otp;
      nonce = query.nonce;
    }

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (otp && nonce) {
      // eslint-disable-next-line no-param-reassign
      config.params = { ...config.params, otp, nonce };
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    if (typeof window !== 'undefined') {
      if (window.location.href.includes('noauth')) {
        return Promise.reject(error);
      }
    }

    const originalRequest = error.config;
    const expiredTokenStatuses = [401, 498];

    if (expiredTokenStatuses.includes(error.response?.status)) {
      if (!originalRequest._retry) {
        try {
          originalRequest._retry = true;

          const accessToken = await refreshAccessToken();

          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return api(originalRequest);
          // eslint-disable-next-line no-shadow
        } catch (error) {
          await clearTokens();

          if (typeof window !== 'undefined') {
            window.location.href = '/';
          }

          throw error;
        }
      }

      await clearTokens();
    }

    return Promise.reject(error);
  }
);

export const handleApiException = (error: any) => {
  if (error.response) {
    console.error(`Axios No Response Error in ${error.request.url}`);
    Sentry.captureException(new Error('Axios Response Error'), {
      extra: {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
        config: error.config,
      },
    });
  } else if (error.request) {
    console.error('Axios No Response Error');
    Sentry.captureException(new Error('Axios No Response Error'), {
      extra: {
        request: error.request,
        config: error.config,
      },
    });
  } else {
    console.error('Axios Request Setup Error');

    Sentry.captureException(new Error('Axios Request Setup Error'), {
      extra: {
        message: error.message,
        config: error.config,
      },
    });
  }
};

export default api;

export const socket = io(process.env.NEXT_PUBLIC_API_ENV, {
  autoConnect: false,
  auth: async cb => {
    const { default: Cookies } = await import('js-cookie');
    const accessToken =
      Cookies.get('access_token') ||
      window?.localStorage?.getItem('access_token');

    if (accessToken) {
      cb({ token: `Bearer ${accessToken}` });
    }
  },
});
